export enum Routes {
  //   MAIN = "/",
  HISTORY = "/history",
  // PRESS = "/press",
  CONFIGURATOR = "/configurator",
  COLLECTION = "/collection",
  CONTACTS = "/contacts",
  PROJECTS = "/projects",
  SHOPS = "/shops",
  // IMPERIAL_PARTNERSHIP = "/imperial_partnership",
}

export interface IRoute {
  id: number;
  key:
    | "projects"
    | "history"
    | "contacts"
    | "configurator"
    | "collection"
    | "shops";
  //  | "press";
  // | "imperial partnership";
  path: Routes;
  disabled: boolean;
}

export const routes: IRoute[] = [
  {
    id: 0,
    key: "history",
    path: Routes.HISTORY,
    disabled: false,
  },
  {
    id: 1,
    key: "collection",
    path: Routes.COLLECTION,
    disabled: false,
  },
  {
    id: 2,
    key: "configurator",
    path: Routes.CONFIGURATOR,
    disabled: true,
  },
  {
    id: 3,
    key: "projects",
    path: Routes.PROJECTS,
    disabled: true,
  },
  // {
  //   id: 4,
  //   key: "press",
  //   path: Routes.PRESS,
  //   disabled: true,
  // },
  {
    id: 4,
    key: "shops",
    path: Routes.SHOPS,
    disabled: false,
  },
  {
    id: 5,
    key: "contacts",
    path: Routes.CONTACTS,
    disabled: false,
  },
];
