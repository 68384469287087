import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Box from "../components/Box/Box";
import Layout from "../components/Layout/Layout";
import ScrollAvailable from "../components/ScrollAvailable";
import HistorySection from "../components/Section";
import Seo from "../components/Seo";
import { Paragraph, Paragraph2, Title } from "../components/Styled/Text";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { HistoryPageProps as Props } from "../types/pages/history";
import LinkArrowBtn from "../components/LinkArrowBtn";
import { Routes } from "../routes";
import Wrapper from "../components/Wrapper";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";

const History = ({ data, location }: Props) => {
  const { height } = useWindowSize();
  return (
    <>
      <Seo
        title={data.translationsJson.pages.history.seo.title}
        description={data.translationsJson.pages.history.seo.description}
        keywords={data.translationsJson.pages.history.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.history.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#ffff",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                fontFamily: "custom_42266",
                fontSize: "21px",
                fontWeight: 400,
                color: get("palette.text.dark_gold"),
                marginBottom: "30px",
                visibility: "hidden",
              }}
              as="span"
            >
              {data.translationsJson.pages.history.header.subtitle}
            </Box>
            {data.translationsJson.pages.history.header.title.map((t, i) => (
              <Title variant="bigHero" key={i} sx={{ color: "white" }}>
                {t}
              </Title>
            ))}

            <Paragraph2
              variant="hero"
              sx={{
                color: "#fff",
                opacity: 0.85,
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.history.header.description}
            </Paragraph2>
          </Box>
          <GatsbyImageWithArtDirection
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Box>
        <Wrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: height,
              textAlign: "center",
              padding: "0 7.5%",
            }}
          >
            <Title variant="section">
              {data.translationsJson.pages.history.timeline.header.title}
            </Title>
            <Paragraph
              sx={{
                width: [
                  "100%",
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                ],
              }}
            >
              {data.translationsJson.pages.history.timeline.header.description}
            </Paragraph>
          </Box>
          <Box
            sx={{
              position: "relative",
              padding: ["25px 0", "25px 0", "150px 0", "150px 0"],
              marginBottom: [0, 0, "65px", "65px"],
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                position: "absolute",
                fontSize: "32px",
                height: "32px",
                fontFamily: "custom_42269",
                color: get("palette.text.gold"),
                top: "-61px",
                display: ["none", "none", "block", "block"],
              }}
              as="span"
            >
              {data.translationsJson.pages.history.timeline.sections[0].dates}
            </Box>
            <Box
              sx={{
                position: "absolute",
                display: ["none", "none", "block", "block"],
                bottom: 0,
                width: "6px",
                height: "6px",
                left: "50%",
                transform: "translateX(-50%) rotate(45deg)",
                backgroundColor: get("palette.text.gold"),
              }}
            ></Box>
            {data.translationsJson.pages.history.timeline.sections.map(
              (n, i) => (
                <HistorySection
                  key={n.order}
                  sectionElm={n}
                  sx={
                    i === 0
                      ? {
                          position: [
                            "relative",
                            "relative",
                            "static",
                            "static",
                          ],
                        }
                      : undefined
                  }
                  sxDates={
                    i === 0
                      ? {
                          top: ["-61px", "-61px", "-85px", "-85px"],
                          left: ["unset", "unset", "50%", "50%"],
                          display: ["initial", "initial", "none", "none"],
                        }
                      : undefined
                  }
                  sxTextContainer={{
                    alignSelf: "flex-start",
                    "& > p": {
                      maxWidth: [
                        "100%",
                        clampBuilder(599, 1680, 12.875, 31.125),
                        clampBuilder(599, 1680, 12.875, 31.125),
                        clampBuilder(599, 1680, 12.875, 31.125),
                      ],
                      alignSelf: "flex-start",
                    },
                  }}
                  isPageOnLeft={i % 2 === 0 ? true : false}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      display: ["none", "none", "block", "block"],
                      top: 0,
                      width: "6px",
                      height: "6px",
                      left: "50%",
                      transform: "translateX(-50%) rotate(45deg)",
                      backgroundColor: get("palette.text.gold"),
                    }}
                  />
                </HistorySection>
              )
            )}

            <Box
              as="div"
              sx={{
                display: ["none", "none", "block", "block"],
                position: "absolute",
                width: "0.75px",
                height: "100%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                margin: "auto 0",
                background: get("palette.text.gold"),
              }}
            >
              {/* <Box
            sx={{
              width: 6,
              height: 6,
              marginLeft: "0.375px",
              left: "50%",
              transform: "translateX(-50%) rotate(45deg)",
              backgroundColor: get("palette.text.gold"),
            }}
          /> */}
            </Box>
            <ScrollAvailable
              inputRange={[0, 80]}
              outputRange={[1, 0]}
              colorsIputRange={[0, 80]}
              colorsOutputRange={["#fff", "#fff"]}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "40px",
              padding: "0 7.5%",
            }}
          >
            <Box
              sx={{
                fontSize: "32px",
                fontFamily: "custom_42269",
                color: get("palette.text.gold"),
                marginBottom: "28px",
              }}
              as="span"
            >
              {data.translationsJson.pages.history.timeline.footer.dates}
            </Box>
            <Title
              sx={{
                textAlign: "center",
                maxWidth: [
                  "100%",
                  clampBuilder(599, 1680, 25.625, 48.5),
                  clampBuilder(599, 1680, 25.625, 48.5),
                  clampBuilder(599, 1680, 25.625, 48.5),
                ],
              }}
              variant="paragraph"
            >
              {data.translationsJson.pages.history.timeline.footer.title}
            </Title>
            <Paragraph
              sx={{
                maxWidth: [
                  "100%",
                  clampBuilder(599, 1680, 25, 60),
                  clampBuilder(599, 1680, 25, 60),
                  clampBuilder(599, 1680, 25, 60),
                ],
                marginBottom: [
                  "75px",
                  "75px",
                  clampBuilder(599, 1680, 1.8125, 4.0625),
                  clampBuilder(599, 1680, 1.8125, 4.0625),
                ],
              }}
            >
              {data.translationsJson.pages.history.timeline.footer.description}
            </Paragraph>
            <Box
              as={GatsbyImage}
              sx={{
                width: "100%",
                maxWidth: ["224px", "476px", "448px", "448px"],
                height: ["327px", "677px", "633px", "633px"],
                marginBottom: "20px",
              }}
              image={
                data.translationsJson.pages.history.timeline.footer.image
                  .childImageSharp.gatsbyImageData
              }
              alt={data.translationsJson.pages.history.timeline.footer.imageAlt}
              // placeholder="blurred"
              // layout="fixed"
              // width={200}
              // height={200}
            />
          </Box>
          <LinkArrowBtn
            title={data.translationsJson.pages.history.link}
            to={Routes.COLLECTION}
            as={Link}
          />
        </Wrapper>
      </Layout>
    </>
  );
};

export default React.memo(History);

export const historyAssets = graphql`
  query historyPageContent {
    headerDesktop: file(
      relativeDirectory: { eq: "pages/history/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/history/header" }
      name: { eq: "mobile" }
    ) {
      id
      relativeDirectory
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    translationsJson {
      pages {
        history {
          link
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          header {
            description
            subtitle
            title
          }
          timeline {
            footer {
              dates
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
            }
            header {
              title
              description
            }
            sections {
              order
              dates
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAlt
            }
          }
        }
      }
    }
  }
`;
